import React from "react";

export const SectionLineLeft = () =>
    <div className="section-line-left"></div>

export const SectionLineRight = () =>
    <div className="section-line-right"></div>

export const SectionLineLeftInverted = () =>
    <div className="section-line-left-inverted"></div>
    