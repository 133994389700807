import * as React from "react"
import CookieConsent from "react-cookie-consent"
import { ContactLink } from "../components/ContactLink"
import { ContentContainer } from "../components/ContentContainer"
import { Logo } from "../components/Logo"
import { NavBar } from "../components/NavBar"
import { Paragraph } from "../components/Paragraph"
import { SectionLineLeft, SectionLineLeftInverted, SectionLineRight } from "../components/SectionEnd"
import { SectionHeader } from "../components/SectionHeader"
import { ElCarIcon } from "../components/ElCarIcon"

const HomePage = () => {
  return (
    <main>
      <NavBar />
      <section className="text-white">
        <ContentContainer>
          <div className="p-8 md:px-16 pt-10 grid grid-cols-1 justify-items-center">
            <Logo className="w-60 md:w-80" />
            <blockquote className="text-xl md:text-2xl font-open-sans italic font-thin mt-4">
              <p>"Proto jsou zákony dány, aby silnější nemohl všechno" </p>
              <p className="text-right">— Marcus Tullius Cicero</p>
            </blockquote>
          </div>
        </ContentContainer>
      </section>
      <SectionLineLeftInverted />
      <section id="onas" className="bg-white p-8 md:px-16">
        <ContentContainer>
          <SectionHeader>O nás</SectionHeader>
          <Paragraph><h3 className="font-bold">JUDr. Petr Jan Bakeš</h3></Paragraph>
          <Paragraph>Jsme malá dynamická advokátní kancelář zaměřená na právní pomoc a obhajobu v trestním, správním a přestupkovém řízení, nejsou nám cizí ani citlivé rodinné záležitosti.</Paragraph>
          <Paragraph>Advokátní kancelář Bakeš chrání oprávněné zájmy klientů, jenž hájí a prosazuje ze všech sil a všemi dostupnými prostředky.</Paragraph>
          <Paragraph>Jsme loajální, diskrétní a maximální oporou našim klientům.</Paragraph>
          <Paragraph><ContactLink /></Paragraph>
        </ContentContainer>
      </section>
      <SectionLineRight />
      <section id="cenik" className="text-white p-8 md:px-16">
        <ContentContainer>
          <SectionHeader>Ceník</SectionHeader>
          <Paragraph>Ceny služeb jsou individuální a konečné bez dalších skrytých poplatků a doplatků. Potřeby klienta posoudíme, navrhneme způsob řešení a navrhneme cenu služeb, aby klient měl možnost zvážit zda cena právní služby nepřesahuje hodnotu toho, čeho chtěl dosáhnout. V odůvodněných případech se cena odvíjí podle výsledku a to vždy po předchozí domluvě s klientem.</Paragraph>
          <Paragraph>Čas strávený běžnou vzájemnou komunikací klientovi neúčtujeme, klient se má na advokáta obracet s důvěrou a bez obav z dalších nákladů.</Paragraph>
        </ContentContainer>
      </section>
      <section id="konzultace" className="bg-white p-8 md:px-16">
        <ContentContainer>
          <SectionHeader>Konzultace</SectionHeader>
          <Paragraph>Nabízíme možnost bezplatné a nezávazné konzultace prostřednictvím telefonního hovoru, videokonference nebo při dohodnuté osobní schůzce. </Paragraph>
          <Paragraph><ContactLink /></Paragraph>
        </ContentContainer>
      </section>
      <SectionLineLeft />
      <section id="kontakty" className="text-white p-8 md:px-16">
        <ContentContainer>
          <SectionHeader>Kontakty</SectionHeader>
          <Paragraph className="flex gap-4"><div>Telefon:</div> <div className="font-bold"><a className="hover:underline" href="tel:+420733566180">733{'\u00A0'}566{'\u00A0'}180</a></div></Paragraph>
          <Paragraph className="flex gap-4"><div>E-mail:</div> <div className="font-bold"><a className="hover:underline" href="mailto:advokat@akbakes.cz">advokat@akbakes.cz</a></div></Paragraph>
          <Paragraph className="flex gap-4"><div>Dat. schránka:</div> <div>cvggrbw</div></Paragraph>
          <Paragraph className="flex gap-4"><div>IČO:</div> <div>17249732</div></Paragraph>
          <Paragraph className="flex gap-4"><div>ČAK:</div> <div>20396</div></Paragraph>
          <Paragraph className="flex gap-4"><div>Adresa: </div><div className="flex flex-wrap gap-2">
            <div>U Olší 280,</div>
            <div>Velký Borek 277{'\u00A0'}31,</div>
            <div>GPS: <a href="http://maps.apple.com/maps?q=50.343522611153155,14.523867392433885" target="_blank">50.3435169N, 14.5238761E</a></div>
          </div>
          </Paragraph>
          <div className="max-w-lg aspect-auto pt-8">
            <iframe className="border-none max-w-full w-full" src="https://frame.mapy.cz/s/cozepucebe" height="333" frameBorder="0"></iframe>
          </div>
          <Paragraph className="mt-8"><ElCarIcon />Naše kancelář používá ke svému provozu elektromobil. <a className="font-bold hover:underline underline-offset-2" href="/elektromobilita.pdf" target="_blank">Více informací.</a></Paragraph>
        </ContentContainer>
      </section>
      <CookieConsent
          style={{ "backgroundColor" : "rgb(38,38,38)" }}    
          declineButtonStyle={{ "backgroundColor" : "rgb(185,28,28)" }}
          buttonStyle={{ "backgroundColor" : "rgb(250,204,21)" }}                    
          location="bottom"
          buttonText="Souhlasím"
          declineButtonText="Odmítnout"
          enableDeclineButton={true}
          cookieName="gatsby-gdpr-google-analytics">
          K provozu této webové stránky využíváme cookies k měření funkčnosti a návštěvnosti. Tyto data nám pomáhají zlepšovat naše služby.
        </CookieConsent>
    </main>
  )
}

export const Head = () => (<>
  <title>Bakeš - Advokátní kancelář</title>
  <meta name="description" content="Advokátní kancelář - JUDr. Petr Jan Bakeš" />
  <meta property="og:site_name" content="Bakeš - Advokátní kancelář" />
  <meta property="og:title" content="Advokátní kancelář - JUDr. Petr Jan Bakeš" />
  <meta property="og:description" content="Jsme malá dynamická advokátní kancelář zaměřená na právní pomoc a obhajobu v trestním, správním a přestupkovém řízení, nejsou nám cizí ani citlivé rodinné záležitosti." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.akbakes.cz" />
  <meta name="twitter:title" content="Advokátní kancelář - JUDr. Petr Jan Bakeš" />
  <meta name="twitter:description" content="Jsme malá dynamická advokátní kancelář zaměřená na právní pomoc a obhajobu v trestním, správním a přestupkovém řízení, nejsou nám cizí ani citlivé rodinné záležitosti." />
  <link rel="canonical" href="https://www.akbakes.cz" />
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="crossorigin" />
  <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@300;700&family=Open+Sans:ital,wght@0,300;0,400;1,300&display=swap" rel="stylesheet"></link>
</>
)

export default HomePage
