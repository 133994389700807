import React, { useState } from "react";
import { ContentContainer } from "./ContentContainer";
import { LogoIcon } from "./LogoIcon";

export const NavBar = () => {
    const [visible, setVisible] = useState(false)

    return <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5">
        <ContentContainer>
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                <h1>
                    <a href="/" className="flex items-center">
                        <LogoIcon className="mr-3 h-6 sm:h-9 fill-brand-primary" />
                        <span className="self-center text-xl whitespace-nowrap font-open-sans">Advokátní kancelář Bakeš</span>
                    </a>
                </h1>
                <button onClick={() => setVisible(!visible)} type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Otevřít menu</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                </button>
                <div className={(!visible ? "hidden" : "") + " w-full md:block md:w-auto"} onClick={() => setVisible(false)}>
                    <ul className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border font-merriweather border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-bold md:border-0 md:bg-white">
                        <Item href="#onas">O nás</Item>
                        <Item href="#cenik">Ceník</Item>
                        <Item href="#konzultace">Bezplatná konzultace</Item>
                        <Item href="#kontakty">Kontakty</Item>
                    </ul>
                </div>
            </div>
        </ContentContainer>
    </nav>
}

const Item: React.FC<{ href: string, children: React.ReactNode }> = ({ href, children }) => <li>
    <a href={href} className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-brand-primary md:p-0">{children}</a>
</li>