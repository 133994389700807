import React from "react";

export const ElCarIcon = () => ( <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
      height: "2em",
      display: "inline-block",
      marginRight: "1em",
    }}
    viewBox="0 0 681 206"
  >
    <path
      d="M630.42 958.59c-151.912-12.892-265.359 8.208-337.573 65.75 20.833 11.34 30.629 36.89 31.851 61.15h28.173l27.951.09s-1.877-9.87-1.877-11.43c0-30.09 24.426-54.51 54.511-54.51 30.086 0 54.512 24.42 54.512 54.51 0 1.54-.192 3.68-.422 5.69-.34 2.97-.762 5.65-.762 5.65h281.715c-.864-3.85-.926-7.23-.926-11.34 0-30.09 24.426-54.51 54.511-54.51 30.086 0 54.512 24.42 54.512 54.51 0 3.9-.127 7.68-.907 11.34h70.972s3.151-45.3.356-55.36c-10.133-36.502-3.974-54.501 16.08-69.776-28.661-1.911-44.223-6.706-65.397-13.592-29.749-9.674-73.376-36.53-113.101-44.457-46.387-9.256-124.819-14.556-165.223-11.083-27.743 2.385-57.404 19.15-77.198 31.921"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeWidth: "10.42px",
      }}
      transform="translate(-287.639 -884.942)"
    />
    <path
      d="M1083.35 924.041v56.547h-59.58c-17.36-18.586-15.89-37.457 0-56.547h59.58Z"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeWidth: "10.42px",
      }}
      transform="rotate(4.534 11795.328 -7915.911)"
    />
    <path
      d="M1083.35 924.041h-59.58 59.58Z"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeWidth: "13.98px",
      }}
      transform="matrix(.33046 .0262 -.07904 .99687 153.786 -879.001)"
    />
    <path
      d="M1083.35 924.041h-59.58 59.58Z"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeWidth: "14.1px",
      }}
      transform="matrix(.30237 .02398 -.07904 .99687 182.545 -854.72)"
    />
  </svg>)
